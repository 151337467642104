<template>
  <div class="settings__container">
    <div class="settings__block">
      <img src="@/assets/image/lock-password.png" alt="">
      <div class="settings__block-inner">
        <span>Пароль</span>
        <p>Для управления паролем входа в систему</p>
      </div>
      <div class="settings__block-btn">
        <Button
            @click="$emit('open-modal-change-password')"
            color="gray"
        >
          Изменить пароль
        </Button>
      </div>
    </div>
    <div class="settings__block">
      <img src="@/assets/image/email-file.png" alt="">
      <div class="settings__block-inner">
        <span>Электронная почта</span>
        <p>Измените адрес электронной почты при необходимости</p>
      </div>
      <div class="settings__block-btn">
        <Button
            @click="$emit('open-modal-change-email')"
            color="gray"
        >
          Изменить электронную почту
        </Button>
      </div>
    </div>
    <div class="settings__block">
      <img src="@/assets/image/shield-auth.png" alt="">
      <div class="settings__block-inner">
        <span>Google аутентификация</span>
        <p>Подключите двухфакторную аутентификацию для более надёжной защиты аккаунта</p>
      </div>
      <div class="settings__block-btn">
        <Button
            v-if="!user?.is_2fa_enabled"
            @click="$emit('open-modal-2fa')"
            color="gray"
        >
          Подключить двухфакторную аутентификацию
        </Button>
        <Button
            v-else
            @click="$emit('open-disabled-2fa')"
            color="gray"
        >
          Отключить двухфакторную аутентификацию
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import {mapState} from "vuex";

export default {
  computed: {
    ...mapState('auth', ['user'])
  },
  components: {
    Button,
  }
}
</script>

<style lang="scss" scoped>
@import "settingsContainer";
</style>