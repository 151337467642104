<template>
  <div class="referral-program">
    <div class="_container">
      <div class="referral-program__wrapper">
        <h2 class="referral-program__title">Реферальная программа</h2>
        <div class="referral-program__invite">
          <div class="referral-program__invite-text">
            <span>Ваша реферальная ссылка</span>
            <div class="referral-program__invite-statistic desktop">
              <div>
                <span>Заработано с реферралов:</span>
                <span>{{ referralsInfo?.rewards_sum }}$</span>
              </div>
              <div>
                <span>Зарегистрированных:</span>
                <span>{{ referralsInfo?.referrals_count }}</span>
              </div>
            </div>
          </div>
          <div class="referral-program__invite-block">
            <div class="referral-program__invite-link">
              <span v-if="referralCod">{{ url + '/auth/registration/' + this.referralCod?.referral_code }}</span>
              <button @click="copyText">
                <div class="referral-program__invite-line"></div>
                <span>Copy</span>
                <img src="@/assets/svg/solar-copy.svg" alt="">
                <div
                    :class="{ 'open': copyAlert }"
                    class="referral-program__invite-alert"
                >
                  Ссылка скопирована
                </div>
              </button>
            </div>
<!--            <div class="btn-desktop">-->
<!--              <Button>-->
<!--                Пригласить по QR коду-->
<!--              </Button>-->
<!--            </div>-->
            <div class="btn-mobile">
              <div
                  :class="{ 'open': copyAlert }"
                  class="referral-program__invite-alert"
              >
                Ссылка скопирована
              </div>
              <Button @click="copyText">
                Скопировать ссылку
              </Button>
            </div>
          </div>
        </div>
        <div class="referral-program__invite-statistic mobile">
          <div>
            Заработано с реферралов
            <span>{{ referralsInfo?.rewards_sum }}$</span>
          </div>
          <div>
            Зарегистрированных:
            <span>{{ referralsInfo?.referrals_count }}</span>
          </div>
        </div>
        <div class="referral-program__container">
          <div class="referral-program__image">
            <img class="referral-program__image-bg" src="@/assets/image/referral-bg.png" alt="">
            <div class="referral-program__image-blur"></div>
          </div>
          <div class="referral-program__referrals">
            <h3>
              Мои
              <span>рефералы</span>
            </h3>
            <div class="referral-program__list">
              <empty-block v-if="referralsInfo.referral_rewards?.length === 0">
                У вас нет рефераллов
              </empty-block>
              <ReferralItem
                  v-else
                  v-for="referral in referralsInfo?.referral_rewards"
                  :referral="referral"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="how-work">
        <h3 class="how-work__title">
          <span>Как работает</span>
          <span>реферальная программа?</span>
        </h3>
        <div class="how-work__container">
          <HowWorkBlock
              :how-works="howWorks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import ReferralItem from "@/components/ReferralItem/ReferralItem.vue";
import HowWorkBlock from "@/components/HowWorkBlock/HowWorkBlock.vue";
import EmptyBlock from "@/components/EmptyBlock/EmptyBlock.vue";
import {mapState} from "vuex";

export default {
  data() {
    return {
      howWorks: [
        { id: 0, title: 'Реферальная программа линейная. 4 линии доходности с продвижения нашей платформы. Вознаграждение начисляется с покупки билета розыгрыша.' },
        { id: 1, title: 'С каждой покупки билета, лично приглашенного клиента, вы получаете 3% вознаграждения от стоимости билета.' },
        { id: 2, title:
              `1 линия 3% вознаграждения <br>
               2 линия 2% вознаграждения <br>
               3 линия 1% вознаграждения <br>
               4 линия 1% вознаграждения`
        }
      ],
      url: '',
      copyAlert: false,

    }
  },
  methods: {
    copyText() {
      const textToCopy = `${this.url}/auth/registration/${this.referralCod?.referral_code}`
      const textarea = document.createElement('textarea')

      textarea.value = textToCopy
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)

      this.copyAlert = true

      setTimeout(() => {
        this.copyAlert = false
      }, 2000)
    }
  },
  mounted() {
    const currentUrl = window.location.href
    this.url = currentUrl.split('/').slice(0, 3).join('/')

    this.$store.dispatch('referrals/getInfoReferrals')
    this.$store.dispatch('referrals/getReferralCod')
  },
  computed: {
    ...mapState('referrals', ['referralsInfo', 'referralCod']),
  },
  components: {
    EmptyBlock,
    HowWorkBlock,
    ReferralItem,
    Button,
  }
}
</script>

<style lang="scss" scoped>
@import "_referralProgram";
</style>