<template>
  <div class="modal">
    <div class="modal__container">
      <div class="modal__content">
        <div class="modal-disabled-2fa">
          <button @click="$emit('close-modal')" class="modal-disabled-2fa__exit-btn">
            <img src="@/assets/svg/exit-icon.svg" alt="exit">
          </button>
          <div class="modal-disabled-2fa__container">
            <div class="modal-disabled-2fa__head">
              <img src="@/assets/image/shield-auth.png" alt="">
              <h2>Отключение Google аутентификации</h2>
            </div>
            <div class="modal-disabled-2fa__instructions">
              <span>Введите 6-значный код, который сгенерировался в приложении</span>
            </div>
            <Input
                :placeholder="'Пожалуйста, введите 6-значный код'"
                :type="'number'"
                @enter-input-modal="enterCode"
            />
            <Button @click="disable2FA">
              Подтвердить код
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div @click="$emit('close-modal')" class="modal__overlay"></div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";
import { mapState } from "vuex";

export default {
  data() {
    return {
      code2fa: ''
    }
  },
  methods: {
    enterCode(input) {
      this.code2fa = input
    },
    disable2FA() {
      this.$store.dispatch('settings/disable2FA', { data: { one_time_password: this.code2fa.toString() }, component: this })
    },
    closeModal() {
      this.$emit('close-modal')
    },
  },
  components: {
    Input,
    Button,
  },
}
</script>

<style lang="scss" scoped>
@import "modalDisabled2FA";
</style>