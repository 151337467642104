<template>
  <div class="tabs">
    <button
        class="tab"
        v-for="tab in tabs"
        :key="tab.id"
        @click="openTab(tab)"
    >
      <img :src="tab.img" alt="">
      <span>{{ tab.title }}</span>
      <div class="disable" :class="{ 'active': tab.id === selectedTab }"></div>
    </button>
  </div>
</template>

<script>
import Ticket from '@/assets/svg/ticket-fill.svg'
import HistoryPlay from '@/assets/svg/chat-history-fill.svg'
import Referral from '@/assets/svg/gift-sharp.svg'
import Wallet from '@/assets/svg/clarity_wallet-solid.svg'
import WorkHistory from '@/assets/svg/work-history.svg'
import Settings from '@/assets/svg/settings.svg'
export default {
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { title: 'Доступные лотереи', img: Ticket, link: '/dashboard/available-lotteries', id: 0},
        { title: 'История участия', img: HistoryPlay, link: '/dashboard/history-participation', id: 1},
        { title: 'Реферальная программа', img: Referral, link: '/dashboard/referral-program', id: 2},
        { title: 'Пополнение/вывод средств', img: Wallet, link: '/dashboard/replenishment-withdrawal/deposit', id: 3},
        { title: 'История операций', img: WorkHistory, link: '/dashboard/operations-history', id: 4},
      ]
    }
  },
  methods: {
    openTab(tab) {
      this.$emit('close-burger')
      this.selectedTab = tab.id
      this.$router.push(tab.link)
    }
  },
  mounted() {
    switch (this.$route.path) {
      case '/dashboard/available-lotteries':
        this.selectedTab = 0;
        break;
      case '/dashboard/history-participation':
        this.selectedTab = 1;
        break;
      case '/dashboard/referral-program':
        this.selectedTab = 2;
        break;
      case '/dashboard/replenishment-withdrawal/deposit':
        this.selectedTab = 3;
        break;
      case '/dashboard/replenishment-withdrawal/withdrawal':
        this.selectedTab = 3;
        break;
      case '/dashboard/operations-history':
        this.selectedTab = 4;
        break;
      case '/dashboard/settings':
        this.selectedTab = 5;
        break;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "dashboardTabs";
</style>