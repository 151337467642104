<template>
  <div class="replenishment-withdrawal">
    <div class="_container">
      <div class="replenishment-withdrawal__wrapper">
        <h2 class="replenishment-withdrawal__title">
          {{ openRW ? 'Пополнить депозит' : 'Вывести средства' }}
        </h2>
        <div class="replenishment-withdrawal__container">
          <RWTabs
              @open-replenishment="openRW = true"
              @open-withdrawal="openRW = false"
          />
          <RouterView />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RWTabs from "@/components/UI/RWTabs/RWTabs.vue";
import Replenishment from "@/components/Replenishment/Replenishment.vue";
import Withdrawal from "@/components/Withdrawal/Withdrawal.vue";

export default {
  data() {
    return {
      openRW: true,
    }
  },
  components: {
    Withdrawal,
    Replenishment,
    RWTabs,
  },
  mounted() {
    const path = '/dashboard/replenishment-withdrawal/'
    switch (this.$route.path) {
      case `${path}deposit`:
        this.openRW = true;
        break;
      case `${path}withdrawal`:
        this.openRW = false;
        break;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "replenishmentWithdrawal";
</style>