<template>
  <button class="button-back">
    <img src="@/assets/svg/back__icon.svg" alt="">
    <span>Назад</span>
  </button>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.button-back {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 24px;
  height: 63px;
  border: 1px solid #161F2D;
  background: #08101D;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 90%;
  color: #697383;
  transition: all 0.3s;

  & > img {
    width: 20px;
    height: auto;
    transform: rotate(0deg);
    transition: all 0.3s;
  }

  &:hover {
    background: #192b48;
    color: #707b8c;

    & > img {
      transform: translateX(-6px);
      transition: all 0.3s;
    }
  }
}
</style>