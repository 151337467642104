<template>
  <div class="withdrawal">
    <div class="withdrawal__left">
      <div class="withdrawal__form">
        <div class="withdrawal__form-title">
          <div>1</div>
          <span>Валюта для вывода</span>
        </div>
        <div class="withdrawal__form-data">
          <div class="withdrawal__form-dashed">
            <div></div>
          </div>
          <div class="withdrawal__form-select">
            <div class="withdrawal__form-input">
              {{ dataWithdrawal[0]?.name }}
            </div>
          </div>
        </div>
        <div class="withdrawal__form-title">
          <div>2</div>
          <span>Укажите адрес для вывода</span>
        </div>
        <div class="withdrawal__form-data">
          <div class="withdrawal__form-dashed">
            <div></div>
          </div>
          <div class="withdrawal__form-select">
            <Input
                :type="'text'"
                :placeholder="'Пожалуйста, введите адрес '"
                @enter-input-modal="setAddress"
            />
          </div>
        </div>
        <div class="withdrawal__form-title">
          <div>3</div>
          <span>Сеть</span>
        </div>
        <div class="withdrawal__form-data">
          <div class="withdrawal__form-dashed">
            <div></div>
          </div>
          <div class="withdrawal__form-select">
            <div class="withdrawal__form-input">
              {{ dataWithdrawal[0]?.blockchains[0] }}
            </div>
          </div>
        </div>
        <div class="withdrawal__form-title">
          <div>4</div>
          <span>Укажите сумму вывода</span>
        </div>
        <div class="withdrawal__form-data">
          <div class="withdrawal__form-dashed"></div>
          <div class="withdrawal__form-select">
            <Input
                :type="'number'"
                :placeholder="'Укажите сумму вывода'"
                @enter-input-modal="setAmount"
            />
          </div>
        </div>
      </div>
      <div class="withdrawal__next">
        <Button @click="withdrawalMoney">
          Вывести
        </Button>
      </div>
    </div>
    <div class="withdrawal__right">
      <div class="withdrawal__right-block">
        <span class="withdrawal__right-name">Доступный баланс:</span>
        <div class="withdrawal__right-dashed"></div>
        <span class="withdrawal__right-value">{{ wallet?.balance }} USDT</span>
      </div>
      <div class="withdrawal__right-block">
        <span class="withdrawal__right-name">Минимальный вывод:</span>
        <div class="withdrawal__right-dashed"></div>
        <span class="withdrawal__right-value">{{ withdrawalInfo ? `${withdrawalInfo?.fee} USDT` : '-' }}</span>
      </div>
      <div class="withdrawal__right-block">
        <div class="withdrawal__right-inner">
          <span class="withdrawal__right-name">Комиссия:</span>
        </div>
        <div class="withdrawal__right-dashed"></div>
        <span class="withdrawal__right-value">{{ withdrawalInfo ? `${withdrawalInfo?.min_withdrawal} USDT` : '-' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import RWSelect from "@/components/UI/RWSelect/RWSelect.vue";
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";
import {mapState} from "vuex";

export default {
  data() {
    return {
      withdrawalData: {
        address: '',
        amount: null
      },
    }
  },
  methods: {
    setAddress(input) {
      this.withdrawalData.address = input
    },
    setAmount(input) {
      this.withdrawalData.amount = input

      const { ticker, blockchains } = this.dataWithdrawal[0]
      this.withdrawalData.ticker = ticker
      this.withdrawalData.blockchain = blockchains[0]

      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.$store.dispatch('withdrawal/getWithdrawalInfo', this.withdrawalData)
      }, 2000)
    },
    withdrawalMoney() {
      const { ticker, blockchains } = this.dataWithdrawal[0]
      this.withdrawalData.ticker = ticker
      this.withdrawalData.blockchain = blockchains[0]
      this.$store.dispatch('withdrawal/createWithdrawalPayment', this.withdrawalData)
    }
  },
  mounted() {
    this.$store.dispatch('wallet/getWallet')
    this.$store.dispatch('withdrawal/getDataWithdrawal')
  },
  computed: {
    ...mapState('wallet', ['wallet']),
    ...mapState('withdrawal', ['dataWithdrawal', "withdrawalInfo"])
  },
  components: {
    Button,
    Input,
    RWSelect,
  }
}
</script>

<style lang="scss" scoped>
@import "withdrawal";
</style>