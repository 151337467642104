<template>
  <div class="logo">
    <img v-if="color === 'gray'" src="@/assets/svg/logo_gray.svg" alt="logo">
    <img v-if="color === 'blue'" src="@/assets/svg/logo_blue.svg" alt="logo">
    <div>
      <span>CRYPTO</span>
      <span>LOT</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'blue'
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  font-size: 22px;
  line-height: 90%;
  color: #2466A4;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  cursor: pointer;

  & > img {
    width: 40px;
    height: auto;
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > span:last-child {
      color: #ECF3FF;
    }
  };
}

@media (max-width: 1680px) {
  .logo {
    font-size: 20px;
    gap: 8px;

    & > img {
      width: 36px;
    }
  }
}

@media (max-width: 1440px) {
  .logo {
    font-size: 18px;
    gap: 7px;

    & > img {
      width: 32px;
    }
  }
}

@media (max-width: 1280px) {
  .logo {
    font-size: 17px;
    gap: 6px;

    & > img {
      width: 30px;
    }
  }
}

@media (max-width: 1140px) {
  .logo {
    font-size: 17px;
    gap: 8px;

    & > img {
      width: 28px;
    }
  }
}
</style>