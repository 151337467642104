<template>
  <div class="new-password">
    <h2 class="new-password__title">Новый пароль</h2>
    <div class="new-password__container">
      <div class="new-password__block">
        <label for="text">Установите пароль</label>
        <Input
            :type="'email'"
            :id="'email'"
            :placeholder="'Пожалуйста, установите новый пароль'"
            :auth="'auth'"
        />
      </div>
      <Button>
        Войти по новому паролю
      </Button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";

export default {
  components: {Button, Input}

}
</script>

<style lang="scss" scoped>
@import "newPassword";
</style>