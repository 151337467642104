<template>
  <div class="replenishment-payment">
    <div class="replenishment-payment__block">
      <div class="replenishment-payment__block-head">
        <div class="replenishment-payment__block-title">
          <div>
            1
          </div>
          <span>Проверьте сумму</span>
        </div>
        <div class="replenishment-payment__block-time">
          <span>Время оплаты: </span>
          <span>{{ formattedCountdownTime  }}</span>
        </div>
      </div>
      <div class="replenishment-payment__block-input">
        <div class="replenishment-payment__block-inner">
          <span>{{ amount }}</span>
        </div>
        <div @click="copyAmount">
          <div></div>
          <span>Copy</span>
          <img src="@/assets/svg/solar-copy.svg" alt="">
        </div>
      </div>
    </div>
    <div class="replenishment-payment__block">
      <div class="replenishment-payment__block-head">
        <div class="replenishment-payment__block-title">
          <div>
            2
          </div>
          <span>На ваш адрес для пополнения</span>
        </div>
      </div>
      <div class="replenishment-payment__block-input">
        <span v-if="!depositInformation">Загрузка адреса...</span>
        <span v-else>{{ depositInformation[0]?.address }}</span>
        <div @click="copyAddress">
          <div></div>
          <span>Copy</span>
          <img src="@/assets/svg/solar-copy.svg" alt="">
        </div>
      </div>
    </div>
    <div class="replenishment-payment__warning">
      <img src="@/assets/svg/warning-payment.svg" alt="">
      <div>
        <span>Внимание:</span>
        <span>Реквизиты для оплаты меняются с каждым платёжом!</span>
      </div>
    </div>
    <div class="replenishment-payment__block">
      <div class="replenishment-payment__block-head">
        <div class="replenishment-payment__block-title">
          <div>
            3
          </div>
          <span>После отправки нажмите на кнопку “Я оплатил”</span>
        </div>
      </div>
    </div>
    <Button @click="replenishmentPayment">
      Я оплатил
    </Button>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import {mapGetters, mapState} from "vuex";
import {login} from "@/api";

export default {
  props: {
    amount: {}
  },
  data() {
    return {

    }
  },
  methods: {
    replenishmentPayment() {
      this.$store.commit('notifications/PAYMENT_MODAL', {
        title: 'Заявка на пополнение отправлена',
        text: 'Для проверки статуса операции перейдите на страницу'
      })
    },
    copyAmount() {
      const amount = this.amount
      if (amount) {
        navigator.clipboard.writeText(amount).then(() => {
          this.$store.commit('notifications/SUCCESS_CHANGE', 'Сумма скопирована')
        })
      }
    },
    copyAddress() {
      const address = this.depositInformation[0]?.address
      if (address) {
        navigator.clipboard.writeText(address).then(() => {
            this.$store.commit('notifications/SUCCESS_CHANGE', 'Адрес скопирован')
        })
      }
    },
  },
  computed: {
    ...mapGetters('deposit', ['formattedCountdownTime']),
    ...mapState('deposit', ['depositInformation'])
  },
  beforeUnmount() {
    this.$store.commit('deposit/stopCountdown')
  },
  components: {
    Button,
  },
}
</script>

<style lang="scss" scoped>
@import "replenishmentPayment";
</style>