<template>
  <div class="modal-2fa">
    <button @click="$emit('close-modal')" class="modal-2fa__exit-btn">
      <img src="@/assets/svg/exit-icon.svg" alt="exit">
    </button>
    <div class="modal-2fa__container">
      <div class="modal-2fa__head">
        <img src="@/assets/image/shield-auth.png" alt="">
        <h2>2FA Google аутентификация</h2>
      </div>
      <div class="modal-2fa__instructions">
        <span>Введите 6-значный код, который сгенерировался в приложении</span>
      </div>
      <Input
          :placeholder="'Пожалуйста, введите 6-значный код'"
          :type="'number'"
          @enter-input-modal="enterCode"
      />
      <Button @click="login2FA">
        Подтвердить код
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";

export default {
  data () {
    return {
      inputCode: ''
    }
  },
  methods: {
    enterCode (inputModal) {
      this.inputCode = inputModal
    },
    login2FA () {
      this.$store.dispatch('auth/login2FA', {
        token: localStorage.getItem('token2fa'),
        one_time_password: this.inputCode.toString(),
      })
    }
  },
  mounted () {

  },
  computed: {

  },
  components: {
    Input,
    Button
  },
}
</script>

<style lang="scss" scoped>
@import "modalLogin2Fa";
</style>