<template>
  <div class="confirm-code">
    <h2 class="confirm-code__title">Код подтверждения</h2>
    <div class="confirm-code__container">
      <div class="confirm-code__block">
        <label for="text">Код подтверждения электронной почты</label>
        <Input
            :type="'email'"
            :id="'email'"
            :placeholder="'Пожалуйста, введите код '"
            :auth="'auth'"
        />
      </div>
      <Button>
        Подтвердить
      </Button>
      <div class="confirm-code__under">
        <router-link to="#">Не получили код?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";

export default {
  components: {Button, Input}

}
</script>

<style lang="scss" scoped>
@import "confirmCode";
</style>