<template>
  <div class="modal-change-email">
    <button @click="closeModal" class="modal-change-email__exit-btn">
      <img src="@/assets/svg/exit-icon.svg" alt="exit">
    </button>
    <div class="modal-change-email__container">
      <div class="modal-change-email__head">
        <img src="@/assets/image/email-file.png" alt="">
        <h2>Смена Email</h2>
      </div>
      <span>Email должен быть существующим</span>
      <Input
          :placeholder="'Введите новый Email'"
          type="email"
          name="email"
          @enter-input-modal="enterEmail"
      />
      <Button @click="changeEmail">
        Изменить Email
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";
import {mapState} from "vuex";

export default {
  data () {
    return {
      email: '',
    }
  },
  methods: {
    enterEmail (inputData) {
      this.email = inputData
    },
    changeEmail() {
      this.$store.dispatch('settings/changeEmail', { email: { email: this.email}, component: this })
    },
    closeModal() {
      this.$emit('close-modal')
    }
  },
  components: {
    Input,
    Button
  },
}
</script>

<style lang="scss" scoped>
@import "modalChangeEmail";
</style>