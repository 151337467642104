<template>
  <div class="lottery-card">
    <div class="lottery-card__image">
      <img :src="room.game?.image" alt="">
      <div></div>
    </div>
    <div class="lottery-card__block">
      <div class="lottery-card__head">
        <h3 class="lottery-card__title">{{ room.game?.title }}</h3>
        <div class="lottery-card__id">ID: {{ room.game?.id }}</div>
        <span class="lottery-card__cost">{{ calculatedCost.toFixed(2) }} USDT</span>
      </div>
      <div class="lottery-card__space-between">
        <div class="lottery-card__progress">
          <div class="lottery-card__progress-text">
            <span>Участники</span>
            <span>{{ room?.participants_count }}/{{ room.game?.max_participants_count }}</span>
          </div>
          <div class="lottery-card__progress-bar">
            <div class="progress-bar__bg"></div>
            <div :style="{width: setPercent + '%' }" class="progress-bar__fill"></div>
          </div>
        </div>
        <div class="lottery-card__buttons">
          <Button
              @click.stop="buyTicket"
          >
            Купить билет
          </Button>
          <CounterButton
              @change-count="changeCount"
          />
        </div>
        <div class="lottery-card__foot">
          <TicketsUserRoom :tickets="room?.user_tickets_count "/>
          <AllTicketsUser :tickets="room?.total_tickets_count" />
        </div>
      </div>
    </div>
    <div class="lottery-card__blur"></div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import CounterButton from "@/components/UI/CounterButton/CounterButton.vue";
import {mapState} from "vuex";
import TicketsUserRoom from "@/components/TicketsUserRoom/TicketsUserRoom.vue";
import AllTicketsUser from "@/components/AllTicketsUser/AllTicketsUser.vue";

export default {
  props: {
    room: {
      type: Object,
    }
  },
  data () {
    return {
      count: 1,
      fillPercent: '0',
    }
  },
  methods: {
    changeCount(count) {
      this.count = count
    },
    buyTicket() {
      this.$store.dispatch('tickets/buyTicket', { id: this.room.id, count: { count: +this.count }, routerParams: this.$route.params })
    },
  },
  mounted() {
    this.$store.dispatch('tickets/getTicketUserRoom', this.$route.params.id.toString())
  },
  computed: {
    ...mapState('tickets', ['ticketUserRoom']),

    setPercent () {
      let participantsCount = this.room?.participants_count
      let fillPercent = Math.round(participantsCount / this.room.game?.max_participants_count * 100)
      return fillPercent
    },
    ticketsText() {
      let count = this.ticketUserRoom?.count

      if (count % 100 >= 5 && count % 100 <= 20) {
        return 'билетов';
      }
      switch (count % 10) {
        case 1: return 'билет';
        case 2:
        case 3:
        case 4: return 'билета';
        default: return 'билетов';
      }
    },
    calculatedCost() {
      return this.count * this.room.game?.ticket_cost
    }
  },
  components: {
    AllTicketsUser, TicketsUserRoom,
    Button,
    CounterButton
  },
}
</script>

<style lang="scss" scoped>
@import "lotteryCard";
</style>