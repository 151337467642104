<template>
  <div class="lottery-member">
    <div class="lottery-member__content">
      <div class="lottery-member__username">
        <div class="lottery-member__avatar">
          <img :src="participant.user?.avatar" alt="">
        </div>
        <span>{{ participant.user?.username }}</span>
      </div>
      <div class="lottery-member__APY">
        <div>
          <span>APY</span>
          <span>{{ participant?.win_chance }}</span>
        </div>
      </div>
      <div class="lottery-member__rank">
        <div>
          <span>Сумма</span>
          <span>{{ participant?.prize }} USDT</span>
        </div>
      </div>
      <div class="lottery-member__status">
        <div>
          <span>Статус</span>
          <span :class="statusInfo.class">{{ statusInfo.text }}</span>
        </div>
      </div>
    </div>
    <div class="lottery-member__tickets">
      <LotteryTicket
          v-for="ticket in participant?.tickets"
          :ticket="ticket"
      />
    </div>
  </div>
</template>

<script>
import LotteryTicket from "@/components/LotteryTicket/LotteryTicket.vue";

export default {
  props: {
    participant: {
      type: Object,
      default: {}
    }
  },
  computed:{
    statusInfo() {
      switch (this.participant?.status) {
        case 'Win':
          return { text: 'Победа', class: 'status-win'}
        case 'Loss':
          return { text: 'Проигрыш', class: 'status-loss'}
        case 'Waiting':
          return { text: 'Ожидает', class: 'status-waiting'}
      }
    }
  },
  components: {
    LotteryTicket
  },
}
</script>

<style lang="scss" scoped>
@import "lotteryMember";
</style>