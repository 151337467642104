<template>
  <div class="dashboard">
    <div class="dashboard__head">
      <DashboardHeader
          @toggle-burger="toggleBurger"
          :is-burger="isBurger"
      />
      <div class="_container">
        <div class="pb-64">
          <div class="navigation">
            <div class="navigation__tabs">
              <DashboardTabs />
            </div>
            <router-link to="/dashboard/settings" class="navigation__profile">
              <div class="navigation__profile-info">
                <span class="navigation__profile-username">{{ user?.username }}</span>
                <span>{{ wallet?.balance }} USDT</span>
              </div>
              <div class="navigation__profile-avatar">
                <img :src="user?.avatar" alt="avatar">
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <RouterView
        @open-modal-change-password="openModal(1)"
        @open-modal-change-email="openModal(2)"
        @open-modal-2fa="openModal(3)"
        @open-disabled-2fa="openDisabled2FA = true"
        :user="user"
    />
    <Error />
    <Success />
    <Modal
        @close-modal="closeModal"
        :toggleModal="toggleModal"
        :openModals="openModals"
    />
    <DashboardBurger
        :is-burger="isBurger"
        @close-burger="closeBurger"
    />
    <ModalDisabled2FA
        v-if="openDisabled2FA"
        @close-modal="openDisabled2FA = false"
    />
    <ModalWaitPayment />
  </div>
</template>

<script>
import DashboardHeader from "@/components/layouts/Dashboard/DashboardHeader/DashboardHeader.vue";
import DashboardTabs from "@/components/UI/DashboardTabs/DashboardTabs.vue";
import Notification from "@/components/Notification/Error/Error.vue";
import LotteryCard from "@/components/LotteryCard/LotteryCard.vue";
import Modal from "@/components/Modals/Modal.vue";
import SettingsContainer from "@/components/SettingsContainer/SettingsContainer.vue";
import Error from "@/components/Notification/Error/Error.vue";
import Success from "@/components/Notification/Success/Success.vue";
import DashboardBurger from "@/components/layouts/Dashboard/DashboardBurger/DashboardBurger.vue";
import ModalDisabled2FA from "@/components/Modals/ModalDisabled2FA/ModalDisabled2FA.vue";
import {mapState} from "vuex";
import ModalWaitPayment from "@/components/Modals/ModalWaitPayment/ModalWaitPayment.vue";

export default {
  data () {
    return {
      toggleModal: false,
      openModals: 0,
      isBurger: false,
      openDisabled2FA: false,
    }
  },
  methods: {
    openModal (num) {
      this.toggleModal = true
      this.openModals = num
    },
    closeModal () {
      this.toggleModal = false
      this.openModals = 0
    },
    toggleBurger () {
      this.isBurger = !this.isBurger
    },
    closeBurger () {
      this.isBurger = false
      document.documentElement.style.overflow = 'auto'
    }
  },
  mounted() {
    this.$store.dispatch('wallet/getWallet')
  },
  computed: {
    ...mapState('wallet', ['wallet']),
    ...mapState('auth', ['user']),
  },
  components: {
    ModalWaitPayment,
    DashboardBurger,
    Success,
    Error,
    SettingsContainer,
    Modal,
    LotteryCard,
    Notification,
    DashboardTabs,
    DashboardHeader,
    ModalDisabled2FA,
  }
}
</script>

<style lang="scss" scoped>
@import "dashboarsLayout";
</style>