<template>
  <div v-if="paymentModal" class="modal">
    <div class="modal__container">
      <div class="modal__content">
        <div class="modal-wait-payment">
          <button @click="closeModal" class="modal-wait-payment__exit-btn">
            <img src="@/assets/svg/exit-icon.svg" alt="exit">
          </button>
          <div class="modal-wait-payment__container">
            <div class="modal-wait-payment__head">
              <img src="@/assets/svg/accept-mark.svg" alt="">
              <h2>{{ paymentModal.title }}</h2>
            </div>
            <div>
              <span>{{ paymentModal.text }}</span>
              <span @click="openOperations">
                Мои операции
              </span>
            </div>
            <Button @click="closeModal">
              Oк
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div @click="closeModal" class="modal__overlay"></div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";
import {mapState} from "vuex";
export default {
  components: {
    Input,
    Button
  },
  methods: {
    closeModal() {
      this.$store.commit('notifications/REMOVE_MODAL')
    },
    openOperations() {
      this.$router.push('/dashboard/operations-history')
      this.$store.commit('notifications/REMOVE_MODAL')
    }
  },
  computed: {
    ...mapState('notifications', ['paymentModal'])
  },

}
</script>

<style lang="scss" scoped>
@import "modalWaitPayment";
</style>