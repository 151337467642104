<template>
  <div class="time-tabs">
    <div
        v-for="tab in tabs"
        :key="tab.id"
        class="time-tabs__tab"
    >
      <input
          :id="'tab-' + tab.id"
          type="radio"
          v-model="selectedTab"
          :value="tab.id"
          @change="sendValueToParent(tab.value)"
      >
      <label :for="'tab-' + tab.id">
        Последние {{ tab.value }} дней
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: [],
    }
  },
  data() {
    return {
      selectedTab: null,
    }
  },
  methods: {
    sendValueToParent(value) {
      this.$emit('tab-selected', value)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "timeTabs";
</style>