<template>
  <div class="history-tabs">
    <div
        @click="openTab(1)"
        class="history-tabs__tab"
        :class="{ 'open':  tab === 1 }"
    >
      Активные
    </div>
    <div
        @click="openTab(2)"
        class="history-tabs__tab"
        :class="{ 'open':  tab === 2 }"
    >
      Завершённые
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 1,
    }
  },
  methods: {
    openTab(num) {
      this.tab = num
      this.$emit('open-history', num)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "historyTabs";
</style>