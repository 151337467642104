<template>
  <div
      class="main-burger"
      :class="{ 'open': isBurger }"
  >
    <div class="_container">
      <div class="main-burger__container">
        <div class="main-burger__buttons">
          <Button
              @click="openRegistration"
          >
            Начать игру
          </Button>
          <Button
              @click="openLogin"
              color="dark"
          >
            Войти
            <img src="@/assets/svg/gridicons_sign-out.svg" alt="">
          </Button>
        </div>
        <nav class="main-burger__nav">
          <router-link to="/" @click="$emit('close-burger')" class="main-burger__link">Главная</router-link>
          <div @click="aboutCompany" class="main-burger__link">О компании</div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import DashboardTabs from "@/components/UI/DashboardTabs/DashboardTabs.vue";

export default {
  props: {
    isBurger: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    openRegistration() {
      if (this.isAuthenticated) {
        this.$router.push('/dashboard/available-lotteries')
      } else {
        this.$router.push('/auth/registration')
      }

      this.$emit('close-burger')
    },
    openLogin() {
      if (this.isAuthenticated) {
        this.$router.push('/dashboard/available-lotteries')
      } else {
        this.$router.push('/auth/authorization')
      }

      this.$emit('close-burger')
    },
    aboutCompany() {
      this.$emit('open-about-company')
      this.$emit('close-burger')
    }
  },
  components: {
    Button,
    DashboardTabs,
  }
}
</script>

<style lang="scss" scoped>
@import "mainBurger";
</style>