<template>
  <header class="header">
    <div class="_container">
      <div class="header__container">
        <div class="header__head">
          <Logo
              color="gray"
              @click="$router.push('/dashboard/available-lotteries')"
          />

          <div class="header__mobile">
            <div class="header__profile">
              <div class="header__profile-info">
                <span
                    class="header__profile-username"
                    @click="$router.push('/dashboard/settings')"
                >
                  {{ user?.username }}
                </span>
                <span>{{ wallet?.balance }} USD</span>
              </div>
              <div
                  class="header__profile-avatar"
                  @click="$router.push('/dashboard/settings')"
              >
                <img :src="user?.avatar" alt="avatar">
              </div>
            </div>
            <button
                @click="toggleBurger"
                :class="{'open': isBurger}"
                class="header__burger"
            >
              <div></div>
              <div></div>
              <div></div>
            </button>
          </div>
        </div>
        <div class="header__winner">
          <WinnerBlock
              v-for="winner in lastRoomsWinners"
              :winner="winner"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import WinnerBlock from "@/components/WinnerBlock/WinnerBlock.vue";
import {mapState} from "vuex";
import {getLastWinners} from "@/api";
import Button from "@/components/UI/Button/Button.vue";
import Logo from "@/components/Logo/Logo.vue";
import router from "@/router";

export default {
  props: {
    isBurger: {

    }
  },
  data () {
    return {
      count: 5,
    }
  },
  methods: {
    router() {
      return router
    },
    toggleBurger() {
      if (!this.isBurger) {
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.documentElement.style.overflow = 'auto'
      }
      this.$emit('toggle-burger')
    },
  },
  mounted() {
    this.$store.dispatch('rooms/getLastWinners', { count: this.count })
    this.$store.dispatch('wallet/getWallet')
  },
  computed: {
    ...mapState('rooms', ['lastRoomsWinners']),
    ...mapState('wallet', ['wallet']),
    ...mapState('auth', ['user']),
  },
  components: {
    Logo,
    Button,
    WinnerBlock,
  }
}
</script>

<style lang="scss" scoped>
@import "dashoardHeader";
</style>