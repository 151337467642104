<template>
  <div class="tickets-user-room">
    Вы имеете
    <span>{{ tickets }} {{ ticketsText }}</span>
    в данной лотереи
  </div>
</template>

<script>
export default {
  props: {
    tickets: {}
  },
  computed: {
    ticketsText() {
      let count = this.tickets

      if (count % 100 >= 5 && count % 100 <= 20) {
        return 'билетов';
      }
      switch (count % 10) {
        case 1: return 'билет';
        case 2:
        case 3:
        case 4: return 'билета';
        default: return 'билетов';
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "ticketsUserRoom";
</style>