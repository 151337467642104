<template>
  <div class="modal">
    <div class="modal__container">
      <div class="modal__content">
        <div class="modal-terms">
          <button @click="$emit('close-modal')" class="modal-terms__exit-btn">
            <img src="@/assets/svg/exit-icon.svg" alt="exit">
          </button>
          <div class="modal-terms__container">
            <div class="modal-terms__head">
              <h2>Пользовательское соглашение</h2>
            </div>
            <div class="modal-terms__block" v-html="terms?.content"></div>
            <Button @click="$emit('close-modal')">
              Понял
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div @click="$emit('close-modal')" class="modal__overlay"></div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";
import {mapState} from "vuex";

export default {
  mounted() {
    this.$store.dispatch('info/getTerms')
  },
  computed: {
    ...mapState('info', ['terms'])
  },
  components: {
    Input,
    Button,
  },
}
</script>

<style lang="scss" scoped>
@import "modalPrivacy";
</style>