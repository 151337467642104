<template>
  <div class="set-password">
    <h2 class="set-password__title">Установить пароль</h2>
    <div class="set-password__container">
      <div class="set-password__block">
        <label for="password">Пароль</label>
        <Input
            :type="'password'"
            :id="'password'"
            :placeholder="'Пожалуйста, введите пароль'"
            :auth="'auth'"
            @enter-input-modal="enterPassword"
        />
      </div>
      <div class="set-password__block">
        <label for="repeat-password">Повторите пароль</label>
        <Input
            :type="'password'"
            :id="'repeat-password'"
            :placeholder="'Пожалуйста, повторите пароль'"
            :auth="'auth'"
            @enter-input-modal="enterRepeatPassword"
            @keyup.enter.stop.prevent="register"
        />
      </div>
      <Button
          @click.stop.prevent="register"
      >
        Зарегистрироваться
      </Button>
      <div class="set-password__under">
        <span>У вас уже есть аккаунт?</span>
        <router-link to="/auth/authorization">Войти</router-link>
      </div>
    </div>
    <div class="set-password__buttons">
      <button class="set-password__social">
        <img src="@/assets/svg/devicon_google.svg" alt="">
        <span>Войти через Google</span>
      </button>
      <button class="set-password__social">
        <img src="@/assets/svg/ic_round-facebook.svg" alt="">
        <span>Войти через Facebook</span>
      </button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";

export default {
  data () {
    return {
      password: '',
      repeatPassword: '',
    }
  },
  methods: {
    enterPassword(inputData) {
      this.password = inputData
      this.$emit('enter-password', inputData)
    },
    enterRepeatPassword(inputData) {
      this.repeatPassword = inputData
    },
    register() {
      if (this.password === this.repeatPassword) {
        this.$emit('register')
      } else {
        this.$store.commit('notifications/ERROR_CHANGE', 'Пароли не совпадают')
      }
    }
  },
  components: {
    Button, Input
  }
}
</script>

<style lang="scss" scoped>
@import "setPassword";
</style>