import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from "@/layouts/MainLayout/MainLayout.vue";
import Main from "@/views/Main/Main.vue";
import DashboardLayout from "@/layouts/DashboardLayout/DashboardLayout.vue";
import AvailableLotteries from "@/views/Dashboard/AvailableLotteries/AvailableLotteries.vue";
import Lottery from "@/views/Dashboard/Lottery/Lottery.vue";
import OperationsHistory from "@/views/Dashboard/OperationsHistory/OperationsHistory.vue";
import ReferralProgram from "@/views/Dashboard/ReferralProgram/ReferralProgram.vue";
import ReplenishmentWithdrawal from "@/views/Dashboard/ReplenishmentWithdrawal/ReplenishmentWithdrawal.vue";
import Settings from "@/views/Dashboard/Settings/Settings.vue";
import AuthenticationLayout from "@/layouts/AuthenticationLayout/AuthenticationLayout.vue";
import Registration from "@/views/Authentication/Registration/Registration.vue";
import Authorization from "@/views/Authentication/Authorization/Authorization.vue";
import PasswordReset from "@/views/Authentication/PasswordReset/PasswordReset.vue";
import SetPassword from "@/views/Authentication/SetPassword/SetPassword.vue";
import ConfirmCode from "@/views/Authentication/ConfirmCode/ConfirmCode.vue";
import NewPassword from "@/views/Authentication/NewPassword/NewPassword.vue";
import HistoryParticipation from "@/views/Dashboard/HistoryParticipation/HistoryParticipation.vue";
import CompletedLottery from "@/views/Dashboard/CompletedLottery/CompletedLottery.vue";
import Replenishment from "@/components/Replenishment/Replenishment.vue";
import Withdrawal from "@/components/Withdrawal/Withdrawal.vue";


const routes = [
  {
    path: '/',
    component: MainLayout,

    children: [
      {
        path: '',
        component: Main,
      }
    ]
  },
  {
    path: '/dashboard',
    component: DashboardLayout,

    children: [
      {
        path: '/dashboard/available-lotteries',
        component: AvailableLotteries,
      },
      {
        path: '/dashboard/lottery/:id',
        component: Lottery,
      },
      {
        path: '/dashboard/completed-lottery/:id',
        component: CompletedLottery,
      },
      {
        path: '/dashboard/history-participation',
        component: HistoryParticipation,
      },
      {
        path: '/dashboard/referral-program',
        component: ReferralProgram,
      },
      {
        path: '/dashboard/replenishment-withdrawal',
        component: ReplenishmentWithdrawal,

        children: [
          {
            path: '/dashboard/replenishment-withdrawal/deposit',
            component: Replenishment,
          },
          {
            path: '/dashboard/replenishment-withdrawal/withdrawal',
            component: Withdrawal,
          },
        ]
      },
      {
        path: '/dashboard/operations-history',
        component: OperationsHistory,
      },
      {
        path: '/dashboard/settings',
        component: Settings,
      },
    ]
  },
  {
    path: '/auth',
    component: AuthenticationLayout,

    children: [
      {
        path: '/auth/registration',
        component: Registration,
      },
      {
        path: '/auth/registration/:code',
        component: Registration,
      },
      {
        path: '/auth/authorization',
        component: Authorization,
      },
      {
        path: '/auth/password-reset',
        component: PasswordReset,
      },
      {
        path: '/auth/set-password',
        component: SetPassword,
      },
      {
        path: '/auth/confirm-code',
        component: ConfirmCode,
      },
      {
        path: '/auth/new-password',
        component: NewPassword,
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(to => {
  if (to.path === '/' && localStorage.getItem('authToken')) {
    router.push('/dashboard/available-lotteries')
  }
})

export default router
