<template>
  <div class="password-reset">
    <h2 class="password-reset__title">Сброс пароля</h2>
    <div class="password-reset__container">
      <div class="password-reset__block">
        <label for="text">Электронная почта или логин</label>
        <Input
            :type="'email'"
            :id="'email'"
            :placeholder="'Пожалуйста, введите электронную почту'"
            :auth="'auth'"
            @enter-input-modal="inputEmail"
        />
      </div>
      <Button @click="sendEmail">
        Далее
      </Button>
      <div class="password-reset__under">
        <span>Я не помню электронную почту</span>
        <router-link to="#">Ввести логин</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";

export default {
  data () {
    return {
      email: ''
    }
  },
  methods: {
    inputEmail (inputData) {
      this.email = inputData
      console.log(this.email)
    },
    sendEmail () {
      this.$store.dispatch('auth/sendResetEmail', { email: this.email})
    }
  },
  components: {
    Button,
    Input,
  }
}
</script>

<style lang="scss" scoped>
@import "passwordReset";
</style>