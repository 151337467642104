<template>
  <div class="replenishment">
    <div class="replenishment__left">
      <ReplenishmentChoice
          v-if="!openPayment"
          @enter-input="enterAmount"
          @open-payment="openPayment = true"
      />
      <ReplenishmentPayment
          v-if="openPayment"
          :amount="amount"
      />
    </div>
    <div class="replenishment__right">
      <EmptyBlock v-if="!openPayment">
        Данные о реквизитах оплаты появятся после заполнения
      </EmptyBlock>
      <div v-if="openPayment" class="replenishment__right-head">
        <qrcode v-if="depositInformation" :value="depositInformation[0]?.address"></qrcode>
        <Loader v-else />
        <span class="replenishment__right-text">
          {{ depositInformation ? 'Сканируйте, чтобы автоматически ввести адрес в приложение для оплаты' : 'Загрузка QR кода...' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import RWSelect from "@/components/UI/RWSelect/RWSelect.vue";
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";
import ReplenishmentChoice from "@/components/ReplenishmentChoice/ReplenishmentChoice.vue";
import EmptyBlock from "@/components/EmptyBlock/EmptyBlock.vue";
import ReplenishmentPayment from "@/components/ReplenishmentPayment/ReplenishmentPayment.vue";
import Qrcode from 'vue-qrcode';
import {mapState} from "vuex";
import Loader from "@/components/UI/Loader/Loader.vue";

export default {
  data() {
    return {
      amount: null,
      openPayment: false,
    }
  },
  methods: {
    enterAmount(input) {
      this.amount = input
    },
  },
  computed: {
    ...mapState('deposit', ['depositInformation'])
  },
  beforeRouteLeave(to, from, next) {
    if (from.path === '/dashboard/replenishment-withdrawal/deposit') {
      this.$store.commit('payment/CHANGE_SELECTED_NETWORK', 'Выберите сеть');
    }
    next();
  },
  components: {
    Loader,
    ReplenishmentPayment,
    EmptyBlock,
    ReplenishmentChoice,
    Input,
    Button,
    RWSelect,
    Qrcode,
  }
}
</script>

<style lang="scss" scoped>
@import "replenishment";
</style>