<template>
  <div class="authorization">
    <h2 class="authorization__title">Авторизация</h2>
    <div class="authorization__container">
      <div class="authorization__block">
        <label for="email">Введите Email</label>
        <Input
            :type="'email'"
            :id="'email'"
            :placeholder="'Пожалуйста, введите Email'"
            :auth="'auth'"
            @enter-input-modal="enterEmail"
        />
      </div>
      <div class="authorization__block">
        <label for="name">Пароль</label>
        <Input
            :type="'password'"
            :id="'password'"
            :placeholder="'Пожалуйста, введите ваш пароль'"
            :auth="'auth'"
            @keyup.enter.prevent="login"
            @enter-input-modal="enterPassword"
        />
      </div>
      <Button
          @click.prevent="login"
      >
        Войти
      </Button>
      <div class="authorization__under">
        <router-link to="/auth/registration">Зарегистрироваться</router-link>
        <div></div>
        <router-link to="/auth/password-reset">Забыли пароль</router-link>
      </div>
    </div>
    <div class="authorization__buttons">
      <button class="authorization__social">
        <img src="@/assets/svg/devicon_google.svg" alt="">
        <span>Войти через Google</span>
      </button>
      <button class="authorization__social">
        <img src="@/assets/svg/ic_round-facebook.svg" alt="">
        <span>Войти через Facebook</span>
      </button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";
import {mapState} from "vuex";

export default {
  data () {
    return {
      dataLogin: {
        email: '',
        password: '',
      },
    }
  },
  methods: {
    enterEmail(dataInput) {
      this.dataLogin.email = dataInput
    },
    enterPassword(dataInput) {
      this.dataLogin.password = dataInput
    },
    login() {
      this.$store.dispatch('auth/login', this.dataLogin)
      setTimeout(() => {
        if (this.needed2fa) {
          this.$emit('open-modal-2fa')
        }
      }, 1000)
    },
  },
  mounted() {

  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('auth', ['needed2fa'])
  },
  components: {
    Button,
    Input
  },
}
</script>

<style lang="scss" scoped>
@import "authorization";
</style>