<template>
  <div class="all-ticket-user">
    Всего {{ ticketsText.payed }}
    <span>{{ tickets }} {{ ticketsText.ticket }}</span>
  </div>
</template>

<script>
export default {
  props: {
    tickets: {}
  },
  computed: {
    ticketsText() {
      let count = this.tickets

      if (count % 100 >= 5 && count % 100 <= 20) {
        return { ticket: 'билетов', payed: 'куплено'};
      }
      switch (count % 10) {
        case 1: return { ticket: 'билет', payed: 'куплен'};
        case 2:
        case 3:
        case 4: return { ticket: 'билета', payed: 'куплено'};
        default: return { ticket: 'билетов', payed: 'куплено'};
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "allTicketsUser";
</style>