<template>
  <div class="modal-accept-buy">
    <button @click="$emit('close-modal')" class="modal-accept-buy__exit-btn">
      <img src="@/assets/svg/exit-icon.svg" alt="exit">
    </button>
    <div class="modal-accept-buy__container">
      <div class="modal-accept-buy__head">
        <img src="@/assets/svg/accept-mark.svg" alt="">
        <h2>{{ successModal }}</h2>
      </div>
      <Button @click="$emit('close-modal')">
        Oк
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import Input from "@/components/UI/Input/Input.vue";
import {mapState} from "vuex";

export default {
  props: {
    successModal: {}
  },
  components: {
    Input,
    Button
  },
}
</script>

<style lang="scss" scoped>
@import "modalAcceptBuy";
</style>