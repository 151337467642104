<template>
  <div class="lottery-card">
    <div class="lottery-card__image">
      <img :src="room.game?.image" alt="">
      <div></div>
    </div>
    <div class="lottery-card__block">
      <div class="lottery-card__head">
        <h3 class="lottery-card__title">{{ room.game?.title }}</h3>
        <div class="available-card__id">ID: {{ room.game?.id }}</div>
      </div>
      <div class="lottery-card__space-between">
        <div class="lottery-card__progress">
          <div class="lottery-card__progress-text">
            <span>Участники</span>
            <span>{{ room?.participants_count }}/{{ room.game?.max_participants_count }}</span>
          </div>
          <div class="lottery-card__progress-bar">
            <div class="progress-bar__bg"></div>
            <div :style="{width: '100%' }" class="progress-bar__fill"></div>
          </div>
        </div>
        <div class="lottery-card__foot">
          <TicketsUserRoom :tickets="room?.user_tickets_count "/>
          <AllTicketsUser :tickets="room?.total_tickets_count" />
        </div>
      </div>
    </div>
    <div class="lottery-card__blur"></div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button/Button.vue";
import TicketsUserRoom from "@/components/TicketsUserRoom/TicketsUserRoom.vue";
import AllTicketsUser from "@/components/AllTicketsUser/AllTicketsUser.vue";
import {mapState} from "vuex";

export default {
  props: {
    room: {
      type: Object,
    }
  },
  methods: {

  },
  mounted() {
    this.$store.dispatch('tickets/getTicketUserRoom', this.$route.params.id.toString())
  },
  computed: {
    ...mapState('tickets', ['ticketUserRoom']),
  },
  components: {
    Button,
    TicketsUserRoom,
    AllTicketsUser
  }
}
</script>

<style lang="scss" scoped>
@import "lotteryCardCompleted";
</style>