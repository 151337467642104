<template>
  <div class="registration">
    <h2 class="registration__title">Создать аккаунт</h2>
    <div class="registration__container">
      <div class="registration__block">
        <label for="name">Имя профиля</label>
        <Input
            :type="'text'"
            :id="'name'"
            :placeholder="'Введите ваше имя профиля '"
            :auth="'auth'"
            @enter-input-modal="enterName"
        />
      </div>
      <div class="registration__block">
        <label for="email">Электронная почта</label>
        <Input
            :type="'email'"
            :id="'email'"
            :placeholder="'Пожалуйста, введите электронную почту'"
            :auth="'auth'"
            @enter-input-modal="enterEmail"
            @keyup.enter="registration"
        />
      </div>
      <Button
          @click="registration"
      >
        Зарегистрироваться
      </Button>
      <div class="registration__under">
        <span>У вас уже есть аккаунт?</span>
        <router-link to="/auth/authorization">Войти</router-link>
      </div>
    </div>
    <div class="registration__buttons">
      <button class="registration__social">
        <img src="@/assets/svg/devicon_google.svg" alt="">
        <span>Войти через Google</span>
      </button>
      <button class="registration__social">
        <img src="@/assets/svg/ic_round-facebook.svg" alt="">
        <span>Войти через Facebook</span>
      </button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/UI/Input/Input.vue";
import Button from "@/components/UI/Button/Button.vue";
import {registration} from "@/api";

export default {
  data() {
    return {
      email: '',
      name: '',
      referralCod: '',
    }
  },
  components: {
    Button,
    Input
  },
  methods: {
    enterEmail(inputData) {
      this.$emit('enter-email', inputData)
      this.email = inputData
    },
    enterName(inputData) {
      this.$emit('enter-name', inputData)
      this.name = inputData
    },
    registration() {
      if (!this.name) {
        this.$store.commit('notifications/ERROR_CHANGE', 'Пожалуйста, введите имя')
      } else if (!this.email) {
        this.$store.commit('notifications/ERROR_CHANGE', 'Пожалуйста, введите Email')
      }

      if (this.email && this.name) {
        this.$router.push('/auth/set-password')
        this.$emit('set-referral-cod' , this.referralCod)
      }
    },
  },
  mounted() {
    this.referralCod = this.$route.params.code
    console.log(this.referralCod)
  }
}
</script>

<style lang="scss" scoped>
@import "registration";
</style>